<template>
  <card type="nav-tabs" class="text-left">
    <h5 slot="header" class="title">Log in</h5>
    <div class="column">
      <div class="col-md-5 pr-md-1 m-auto">
        <base-input label="username" placeholder="username" v-model="username">
        </base-input>
      </div>
      <div class="col-md-5 pr-md-1 m-auto">
        <base-input
          type="password"
          label="Password"
          placeholder="password"
          v-model="password"
        >
        </base-input>
      </div>
      <div class="col-md-5 pr-md-1 m-auto d-flex justify-content-center">
        <base-button
          slot="footer"
          type="primary"
          fill
          @click="login"
          class="m-auto"
        >Login
        </base-button
        >
      </div>
    </div>
  </card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },

  methods: {
    login() {
      this.$axios
        .post(`Account/authenticate`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.$store
            .dispatch('auth/handleSuccessfulLogin', {
              token: response.data.jwtToken,
              refreshToken: response.data.refreshToken,
              user: response.data,
            })
            .then(() => {
              const returnUrl = this.$route.query.from;
              this.$router.push(returnUrl || '/');
              this.loading = false;
            });
        });
    },
  },
};
</script>

<style></style>
